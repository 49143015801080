<template>
  <div class="content">
    <div class="container">
      <div class="dishes-box">
        <div class="logo"><img src="../assets/images/logo.svg" alt="" /></div>

        <div class="day-title font-heading-pro">
          <div class="day-name">Menu</div>
        </div>

        <div class="menu">
          <div
            v-for="dish in permanentDishes"
            :key="dish.id"
            class="dishes-item flex justify-space-between"
          >
            <div class="info">
              <div class="new_dish" v-if="dish.attributes.new_dish">
                <img src="../assets/images/new.gif" alt="New dish" />
              </div>
              <div>
                <div class="title">{{ dish.attributes.title }}</div>
                <div class="small-title">
                  {{ dish.attributes.title_ru }}
                  <span v-if="dish.attributes.title_en?.length">/</span>
                  {{ dish.attributes.title_en }}
                </div>
              </div>
            </div>
            <div class="price">{{ dish.attributes.price }}€</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Main",
  data: () => ({
    // url: "https://api.simpleresto.ee",
    // url: "http://localhost:1337",
    url: "https://dolphin-app-gv5vo.ondigitalocean.app/api",
    dishes: [],
    permanentDishes: [],
    garnishes: [],
    categoryDays: [],
    activeBar: false,
    valueDays: [],
    actionStatus: true,

    dayCurrent: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
    dayCurrentEt: [
      "Pühapäev",
      "Esmaspäev",
      "Teisipäev",
      "Kolmapäev",
      "Neljapäev",
      "Reede",
      "Laupäev",
    ],
    dayCurrentEn: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    today: "",
    todayEt: "",
    todayEn: "",
    todayNumber: 0,
  }),

  mounted() {
    this.getDishes();
    this.getGarnishes();
    let dayTitle = String(new Date().getDay());
    this.today = this.today.replace(this.today, this.dayCurrent[dayTitle]);
    this.todayEt = this.todayEt.replace(
      this.todayEt,
      this.dayCurrentEt[dayTitle]
    );
    this.todayEn = this.todayEn.replace(
      this.todayEn,
      this.dayCurrentEn[dayTitle]
    );
    let getMonth = new Date().getMonth();
    this.todayNumber = `${new Date().getDate()}.${getMonth < 9 ? 0 : ""}${
      getMonth + 1
    }`;
    // this.changeLogo();
  },

  methods: {
    // changeLogo() {
    //   const imageContainer = document.getElementById("logo-container");
    //   const images = imageContainer.getElementsByTagName("div");
    //   const imageCount = images.length;
    //   let currentIndex = 0;

    //   function showNextImage() {
    //     images[currentIndex].classList.remove("active");
    //     currentIndex = (currentIndex + 1) % imageCount;
    //     images[currentIndex].classList.add("active");
    //   }

    //   setInterval(showNextImage, 8000);
    // },

    getDishes() {
      this.axios
      .get(`${this.url}/dishes?pagination%5Blimit%5D=1000&populate=%2A`)
        .then((res) => {
          this.dishes = res.data.data;

          this.permanentDishes = res.data.data.filter(
            (item) => !!item.attributes.permanent_dish
          );

          res.data.data.forEach((item) => {
            console.log(item.attributes.permanent_dish);
          });

          this.dishes = this.dishes.filter((item) => {
            if (item.attributes.permanent_dish) {
              return false;
            }

            item.days = item.attributes.days.data.filter((d) => d.attributes.title === this.today);
            return item.days.length;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getGarnishes() {
      this.axios
        .get(`${this.url}/garnishes?pagination%5Blimit%5D=1000&populate=%2A`)
        .then((res) => {
          this.garnishes = res.data.data;
          this.garnishes = this.garnishes.filter((item) => {
            item.days = item.attributes.days.data.filter((d) => d.attributes.title === this.today);
            return item.days.length;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    dishesNoPromotion() {
      return this.dishes.filter((dish) => !dish.promotion);
    },
    dishesPromotion() {
      return this.dishes.filter((dish) => dish.promotion);
    },
    dishesPermanent() {
      console.log(this.dishes.filter((dish) => dish.permanent_dish));
      return this.dishes.filter((dish) => dish.permanent_dish);
    },
  },
};
</script>

<style lang="scss" scoped>
/*------------------------Dishes------------------------*/
$dark: #19191b;

.font-heading-pro {
  font-family: "Heading Pro";
}

.container {
  padding: 0;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-top: 0 !important;
}

.logo {
  width: 140px;
  margin: 36px auto;
}

.title-menu {
  font-size: 144px;
  line-height: 156px;
  text-align: center;
  letter-spacing: 0.15em;
  font-weight: 600;
}

.title-menu-small {
  text-align: center;
  font-size: 72px;
  line-height: 81px;
  margin-top: 8px;
  letter-spacing: 0.15em;
}

.day-title {
  display: flex;
  justify-content: space-between;
  font-size: 36px;
}

.menu {
  border-top: 1px solid #fff;
  margin: 24px 0 48px;
  padding: 24px 0;
}

.dishes-box {
  padding: 35px 80px;
  width: 800px;
  height: 800px;
  background: url(../assets/images/menu_bg.jpg) no-repeat;
  background-size: cover;

  .dishes-item {
    position: relative;
    gap: 30px;
    margin: 10px 0;

    .info {
      position: relative;
      background: inherit;
      padding-right: 15px;
      z-index: 1;
      display: flex;
      gap: 16px;

      .promo {
        width: 60px;
        flex-shrink: 0;
        position: absolute;
        left: -70px;
        top: 10px;

        img {
          width: 100%;
        }
      }

      .new_dish {
        width: 75px;
        flex-shrink: 0;
        position: absolute;
        left: -102px;
        top: 13px;

        img {
          width: 100%;
        }
      }
    }

    .title,
    .price {
      font-size: 24px;
      line-height: 34px;
      font-weight: 600;
    }

    .small-title {
      line-height: 28px;
      font-size: 18px;
    }
  }
}

/*------------------------Garnish------------------------*/

.garnish-box {
  text-align: center;

  .garnish-title {
    font-size: 72px;
    line-height: 81px;
  }
  .garnish-item {
    margin: 15px 0;
    font-size: 48px;
  }
}
@media (min-width: 2140px) {
  #video-bg {
    width: 2160px;
  }
  .content {
    margin-top: 690px;
  }
}
</style>
